import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common'

import GLightbox from '../../assets/js/glightbox.min.js'

declare var $: any
@Component({
  selector: 'cube',
  templateUrl: './cube.component.html',
  styleUrls: ['./cube.component.css']
})
export class CubeComponent implements OnInit {
  title = 'cube';
  allowClick: boolean = true
  isSafari: boolean = false
  allowMove: boolean = true
  allowKeyPress: boolean = true
  showCubeInBackground: boolean = true
  direction: number = 1
  topView: boolean = false
  wasTopView: boolean = false
  bottomView: boolean = false
  wasBottomView: boolean = false
  hi: number = 0
  wi: number = 0
  currentState: number = 0;   //0 = normal, 1 = left is front, 2 = back is front, 3 = right is front
  configs: Array<Array<number>> = [
    [1, 2, 3, 4], //center, left, back, right
    [2, 3, 4, 1],
    [3, 4, 1, 2],
    [4, 1, 2, 3]
  ];
  dragged: boolean = false
  wasdragged: boolean = false
  startX: number = 0
  startY: number = 0
  zOrigin: number = 200
  cubeSize: number = 700

  constructor(private router: Router, private location: Location) {
  }

  @HostListener('document:keydown.escape', ['$event']) onEscapeHandler(event: KeyboardEvent) {
    this.menuClick(event);
  }
  @HostListener('document:keydown.enter', ['$event']) onEnterHandler(event: KeyboardEvent) {
    this.fullscreenClick(event);
  }
  @HostListener('document:keydown.arrowleft', ['$event']) onLeftHandler(event: KeyboardEvent) {
    if (!$(".current").hasClass("fullscreen")) {
      this.turnLeftClick(event);
    }
  }
  @HostListener('document:keydown.arrowright', ['$event']) onRightHandler(event: KeyboardEvent) {
    if (!$(".current").hasClass("fullscreen")) {
      this.turnRightClick(event);
    }
  }

  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  onMouseDown(e) {
    // we make sure only draggables on the document elements are selected
    if ($(".current").hasClass("fullscreen") && $(e.target).is(".myselect *")) {
      this.menuClick(e);
      return false
    }
    if (!this.allowMove || $(".current").hasClass("fullscreen")) return;
    if ($(e.target).is(".left i") || $(e.target).is(".right i") || $(e.target).is(".menuclose")) {
      this.dragged = false; return false; 
    }
    // close shopping side bar if open
    if ($("main").hasClass("moved") && !$(e.target).is(".cart") && !$(e.target).is(".button")) {
      $("main").toggleClass("moved");
      $(".shopping-list").addClass("hidden");
      this.allowKeyPress = true;
      return;
    //close navigation bar if open
    } else if ($("main").hasClass("tilt") && !$(e.target).is(".hamburger")) {
      $("main").removeClass("tilt");
      this.allowKeyPress = true;
      return;
    //if is on top-view: turn down
    } else if (this.topView || this.bottomView) {
      this.topView = false;
      this.bottomView = false;
      this.dragged = true;
      this.wasdragged = false;

      $(".cube div").removeClass("transit");
      if (e.type == "touchstart") {
        let touchobj = e.changedTouches[0];
        this.startX = touchobj.pageX;
        this.startY = touchobj.pageY;
      } else {
        this.startX = e.pageX;
        this.startY = e.pageY;
      }
      //return;
    // else: do dragging
    } else {
      $("main").css("cursor", "grabbing");
      //dragging does not work with transitions
      $(".cube div").removeClass("transit");
      this.dragged = true;

      // get 'mouse'-position on touch screens and on normal screens
      if (e.type == "touchstart") {
        let touchobj = e.changedTouches[0];
        this.startX = touchobj.pageX;
        this.startY = touchobj.pageY;
      } else {
        this.startX = e.pageX;
        this.startY = e.pageY;
      }
      this.wasdragged = false;
    }
  }
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  onMouseMove(e) {
    if (this.dragged) {

      $(".bottom").addClass("invisible");
      $(".top").addClass("invisible");

      $(".current").removeClass("current");
      this.allowClick = false;

      //
      var c = this.configs[this.currentState];

      //get 'mouse'-location
      if (e.type == "touchmove") {
        let touchobj = e.changedTouches[0];
        var difY = (( - touchobj.pageY + this.startY ) / 2) % 360;
        var difX = (( touchobj.pageX - this.startX ) / 2) % 360;
      } else {
        var difY = (( - e.pageY + this.startY ) / 2) % 360;
        var difX = (( e.pageX - this.startX ) / 2) % 360;
      }

      //if dragged more than 180deg, subtract 360, because range is only from -180 to +180deg
      if (difX >= 180) {
        difX -= 360;
      }

      let rotA = difX;
      let rotB = -90 + difX;
      let rotC = -180 + difX;
      let rotD = 90 + difX;

      if (rotA >= 180) rotA -= 360;
      if (rotA < -180) rotA += 360;
      if (rotB >= 180) rotB -= 360;
      if (rotB < -180) rotB += 360;
      if (rotC >= 180) rotC -= 360;
      if (rotC < -180) rotC += 360;
      if (rotD >= 180) rotD -= 360;
      if (rotD < -180) rotD += 360;

      if (this.wasTopView) {
        //thats it!!
        difY -= 90;
      }
      if (this.wasBottomView) {
        difY += 90;
      }

      //console.log("Vorne: " + rotA + " Links: " + rotB + " Hinten: " + rotC + " Rechts: " + rotD);
      $(".bottom").css("transform", "rotateX(" + (90 + difY) + "deg) rotateZ(" + (-difX) + "deg) rotateZ(0deg) translateZ(-600px)");
      $(".top").css("transform", "rotateX(" + (-90 + difY) + "deg) rotateZ(" + (difX) + "deg) rotateZ(0deg) translateZ(-600px)");

      $(".side" + c[0]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(${difY}deg) rotateZ(0deg) rotateY(${rotA}deg) translateZ(${this.zOrigin}px)` );
      $(".side" + c[1]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(${difY}deg) rotateZ(0deg) rotateY(${rotB}deg) translateZ(${this.zOrigin}px)`  );
      $(".side" + c[2]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(${difY}deg) rotateZ(0deg) rotateY(${rotC}deg) translateZ(${this.zOrigin}px)` );
      $(".side" + c[3]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(${difY}deg) rotateZ(0deg) rotateY(${rotD}deg) translateZ(${this.zOrigin}px)` );

      this.wasdragged = true;
    }
  }
  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onMouseUp(e) {
    $("main").css("cursor", "grab");
    if (!this.dragged) {
      return true
    }
    this.dragged = false;
    if (this.wasdragged) {

      if (e.type == "touchend") {
        let touchobj = e.changedTouches[0];
        var dragX = ((touchobj.pageX - this.startX) / 2) % 360;
        var dragY = ((touchobj.pageY - this.startY) / 2) % 360;
      } else {
        var dragX = ((e.pageX - this.startX) / 2) % 360;
        var dragY = ((e.pageY - this.startY) / 2) % 360;
      }

      //if (dragX === 0) return;

      this.direction = -1; //left
      if (dragX >= 0) this.direction = 1; //right

      //rotate the cube n times, n is depended on dragX
      //just setting the new state here
      for (var i = 0; i < Math.floor((Math.abs(dragX) + 45) / 90); i++) {
        this.currentState = this.nextState(this.currentState, this.direction);
      }

      //change direction, if rotated too far (i.e. 190 <-> 170), so that the cube rotates correct
      if (Math.abs(dragX % 90) < 45) {
        this.direction *= -1;
      }

      if (this.wasTopView && Math.abs(dragY) < 45) {
        this.topView = true;
      } else if (this.wasBottomView && Math.abs(dragY) < 45) {
        this.bottomView = true;
      } else {
        if (dragY > 45 && !this.wasBottomView) { this.topView = true; } else { this.topView = false; }
        if (this.wasBottomView && dragY > 45 + 90) { this.topView = true; }
        if (dragY < -45 && !this.wasTopView) { this.bottomView = true; } else { this.bottomView = false; }
        if (this.wasTopView && dragY < -45 - 90) { this.bottomView = true; }
      }



      //snap to a side
      if (this.wasTopView) {
        this.wasTopView = false;
      }
      if (this.wasBottomView) {
        this.wasBottomView = false;
      }
      this.setSidesTo(this.currentState, this.direction, this.topView, this.bottomView);
      
      e.preventDefault();
      e.stopPropagation();
      return false
    } else {
      // this was a tap
      if ($(e.target).is(".mycontainer") || $(e.target).is(".mycontainer *")) {
        if (!$(".current").is(".fullscreen"))
          this.fullscreenClick(e)
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    //if (timeout) clearTimeout(window.RT);
    /*setTimeout(() =>
    {
      window.location.reload(false);
    }, 100);*/
  }
  @HostListener('mousewheel', ['$event'])
  scroll(event) {
    event.stopPropagation()
  }

  reloadPage(value) {
    $(".current .mycontainer").animate({ scrollTop: 0 }, 100);
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/' + value]);
    }, 100);
  }

  turnLeftClick(e) {
    this.turnLeft();
    this.dragged = false;
    e.stopPropagation();
    return false;
  }
  turnRightClick(e) {
    this.turnRight();
    this.dragged = false;
    e.stopPropagation();
    return false;
  }
  fullscreenClick(e) {
    if (!$(".current").hasClass("fullscreen") && this.allowMove) {
      this.makeCurrentFullscreen();
      $(".current").addClass("fullscreen");
      e.stopPropagation();
    }
    return true;
  }

  ngOnInit() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    GLightbox()
    
    $(".side1 .submytitle").vTicker('init', {pause: 2500, mousePause: false});
    $(".side3 .submytitle").vTicker('init', {pause: 2000, mousePause: false});

    /*this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //if (this.isSafari) {
      //console.log("Safari, yeah");
    //  $(".cube").addClass("safari-transform");
    //}
    let isSafari = window.navigator.userAgent.toLowerCase().indexOf('safari') > -1
    if (isSafari) {
      $("div.mycontent").css("background", "rgba(255,255,255,0.97)")
    }*/

    this.direction = 1;
    this.topView = false;
    this.wasTopView = false;
    this.bottomView = false;
    this.wasBottomView = false;
    this.allowMove = true;
    this.allowClick = true;

    if (window.innerWidth > window.innerHeight) {
      $("div.cube").css("width", Math.floor(window.innerHeight * 0.8) + "px")
      $("div.cube").css("height", Math.floor(window.innerHeight * 0.8) + "px")
      //$("div.cube").css("left", `${Math.floor(window.innerWidth / 2) - Math.floor(window.innerHeight * 0.8 / 2)}px`)
      //$("div.cube").css("top", `${Math.floor(window.innerWidth / 2) - Math.floor(window.innerHeight * 0.8 / 2)}px`)
      //$("div.cube").css("transform", `translate(-${Math.floor(window.innerHeight * 0.8 / 2)}px, -${Math.floor(window.innerHeight * 0.8 / 2)}px)`)
      $("div.cube").css("transform", "none")
      $("div.cube").css("margin-left", `-${Math.floor(window.innerHeight * 0.8 / 2)}px`)
      $("div.cube").css("margin-top", `-${Math.floor(window.innerHeight * 0.8 / 2)}px`)
      this.zOrigin = window.innerHeight / 2 * 0.8 + window.innerWidth / 12
    } else {
      $("div.cube").css("width", Math.floor(window.innerWidth * 0.8) + "px")
      $("div.cube").css("height", Math.floor(window.innerWidth * 0.8) + "px")
      //$("div.cube").css("transform", `translate(-${Math.floor(window.innerWidth * 0.8 / 2)}px, -${Math.floor(window.innerWidth * 0.8 / 2)}px)`)
      this.zOrigin = window.innerWidth / 2 * 0.8 + window.innerHeight / 12
    }
    $(".cube .side1").css("transform", `translateZ(-${this.zOrigin}px) rotateX(0deg) rotateZ(0deg) rotateY(0deg) translateZ(${this.zOrigin}px) `)
    $(".cube .side2").css("transform", `translateZ(-${this.zOrigin}px) rotateX(0deg) rotateZ(0deg) rotateY(-90deg) translateZ(${this.zOrigin}px)`)
    $(".cube .side3").css("transform", `translateZ(-${this.zOrigin}px) rotateX(0deg) rotateZ(0deg) rotateY(180deg) translateZ(${this.zOrigin}px)`)
    $(".cube .side4").css("transform", `translateZ(-${this.zOrigin}px) rotateX(0deg) rotateZ(0deg) rotateY(90deg) translateZ(${this.zOrigin}px)`)
    setInterval(() => {
      if ($(".mycard.current").hasClass("fullscreen")) {
        if ($(".mycard.current .mycontainer").scrollTop() > 0) {
          $(".more").fadeOut();
        } else {
          $(".more").fadeIn();
        }
        if ($(".mycard.current .mycontainer").scrollTop() >= window.innerHeight - 50) {
          $(".myselect i").addClass("white"); //not necessary with current color scheme
        } else {
          $(".myselect i").removeClass("white");
        }
      } else {
        $(".more").fadeOut();
      }
    }, 250);

    if (this.router.url === "/aboutme") {
      this.turnRight();
      $(".mycard").removeClass("transit");
    } else if (this.router.url === "/contact") {
      this.turnLeft();
      $(".mycard").removeClass("transit");
    } else if (this.router.url === "/portfolio") {
      this.turnLeft();
      this.turnLeft();
      $(".mycard").removeClass("transit");
    } else if (this.router.url === "/cube") {
      // to get absolute values from 100%
      this.wi = $(".current").css("width");
      this.hi = $(".current").css("height");
      $(".mycard").css("width", this.wi);
      $(".mycard").css("height", this.hi);
      this.gotoCube();
      $("main").css("visibility", "visible");
      return;
    } else {
      //strange behavior for scrolling on some browsers if this three lines are missing
      this.turnLeft();
      this.turnRight();
      $(".mycard").removeClass("transit");
    }

    this.wi = $(".current").css("width");
    this.hi = $(".current").css("height");
    
    $(".mycard").css("width", this.wi);
    $(".mycard").css("height", this.hi);
    /*if (this.isSafari || !this.showCubeInBackground) {
      //$(".mycard").hide();
    }*/
    $(".current").css("transform", "none");


    // strange bug in firefox and chrome -> blurry text with perspective
    //$(".cube").css("perspective", "none")
    //$(".mycard").hide();

    $(".current").show();
    $(".mycard .myselect").hide();
    $(".mycard.current .myselect").show();

    $(".cube").addClass("fullscreen");
    $(".current").addClass("fullscreen");
    $(".current").css({"left" : "-=" + $(".cube").offset().left + "px", "width": "100vw", "top": "-=" + $(".cube").offset().top + "px", "height": "calc(var(--vh, 1vh) * 100)"});
    $(".current .myselect i").removeClass("fa-expand-arrows-alt");
    $(".current .myselect i").addClass("fa-bars");
    $("main").css("visibility", "visible");
  }

  scrollDown() {
    $(".current .mycontainer").animate({scrollTop: $(window).height() }, 800);
  }

  menuClick(e) {
    if (!this.allowClick || !$(".current").hasClass("fullscreen")) return;
    this.gotoCube();

    e.stopPropagation();
    return false;
  }
  crossClick(e) {
    if (this.allowKeyPress && !$(".current").hasClass("fullscreen") && this.allowMove) {
      this.makeCurrentFullscreen();
      $(".current").addClass("fullscreen");
    }
    e.stopPropagation();
    return false;
  }
  makeCurrentFullscreen() {
    //console.log("make the current side fullscreen")
    $(".mycard").removeClass("transit");
    /*wi = $(".current").css("width");
    hi = $(".current").css("height");*/
    if (this.isSafari || !this.showCubeInBackground) {
      //$(".mycard").hide();
      //$(".cube").css("perspective", "none")
    }
    //$(".cube").css("perspective", "none")
    //$(".mycard").hide();

    $(".menuclose").fadeOut();
    $(".svg").fadeOut();
    $(".mymenu").hide();
    $(".mycard .myselect").hide();
    $(".mycard.current .myselect").show();
    $(".current").show();
    $(".current .afterContent").show();
    $(".cube").addClass("fullscreen");
    $(".current").animate({"left" : "-=" + $(".cube").offset().left + "px", "width": "100vw", "top": "-=" + $(".cube").offset().top + "px", "height": window.innerHeight}, 700, "easeOutBounce");
    $(".current .myselect i").removeClass("fa-expand-arrows-alt");
    $(".current .myselect i").addClass("fa-bars");
    $(".current").css("transform", "none");
  
    $("body").addClass("full");
    $(".more").fadeIn();
  
    if ($(".current").hasClass("side1")) {
      this.location.go("/")
    } else if ($(".current").hasClass("side2")) {
      //window.location.hash = "contact";
      this.location.go("/contact")
    } else if ($(".current").hasClass("side3")) {
      this.location.go("/portfolio")
    } else if ($(".current").hasClass("side4")) {
      this.location.go("/aboutme")
    }
  }

  gotoCube() {
    console.log("goto cube")
    setTimeout(() => {this.allowKeyPress = true;}, 750);
    this.location.go("/cube")
    this.allowKeyPress = false;
    //$("main").css("cursor", "default");
    this.allowMove = false;
    $(".current").removeClass("fullscreen");
      //the cube menu is shown
    $(".mycard .afterContent").hide();

    if (this.isSafari || !this.showCubeInBackground) {
      //$(".mycard").show();
      //$(".cube").css("perspective", "600px")
    }
    //$(".cube").css("perspective", "800px")
    //$(".mycard").show();
    
    $(".current").css("transform", `translateZ(-${this.zOrigin}px) rotateX(0deg) rotateZ(0deg) rotateY(0deg) translateZ(${this.zOrigin}px) `);

    $(".mycard .myselect").hide();
    $(".current .mycontainer").animate({ scrollTop: 0 }, "slow");
    $(".current").animate({"left" : "0", "width": this.wi, "top": "0", "height": this.hi}, 700, "easeOutElastic");
    $(".current .myselect i").removeClass("fa-bars");
    $(".current .myselect i").addClass("fa-expand-arrows-alt");
    $(".cube").removeClass("fullscreen");
    $("body").removeClass("full");
    $(".more").hide();
    $(".menuclose").fadeIn();
    $(".svg").fadeIn();
    $(".mymenu").show();

    if (this.isSafari) {
      $(".left").hide();
      $(".right").hide();
      $(".mycard .myselect").hide();
    }

    //allowKeyPress = true;
    this.allowMove = true;
  }

  turnLeft() {
    console.log("turn left")
    $(".mycard").removeClass("transit");
    $(".side" + this.configs[this.currentState][2]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(0deg) rotateZ(0deg) rotateY(-180deg) translateZ(${this.zOrigin}px)` );
    $(".side" + this.configs[this.currentState][2]).hide();
    $(".side" + this.configs[this.currentState][2]).show();
    $(".side" + this.configs[this.currentState][3]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(0deg) rotateZ(0deg) rotateY(90deg) translateZ(${this.zOrigin}px)` );
    $(".side" + this.configs[this.currentState][3]).hide();
    $(".side" + this.configs[this.currentState][3]).show();
    this.currentState = this.nextState(this.currentState, 1);
    this.setSidesTo(this.currentState, 1, false, false);
  }
  turnRight() {
    console.log("turn right")
    $(".mycard").removeClass("transit");
    $(".side" + this.configs[this.currentState][2]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(0deg) rotateZ(0deg) rotateY(180deg) translateZ(${this.zOrigin}px)` );
    $(".side" + this.configs[this.currentState][2]).hide();
    $(".side" + this.configs[this.currentState][2]).show();
    this.currentState = this.nextState(this.currentState, -1);
    this.setSidesTo(this.currentState, -1, false, false);
  }


  nextState(val, dir) {
    return (val + 2 - dir) % 4; //adds or substracts from currentState, depending on direction
  }
  
  //set the cube to a state. Direction is important, because of -180 <-> 180 rotation
  setSidesTo(val, dir, topView, bottomView) {
    this.allowClick = false;
    setTimeout(() => {
      this.allowClick = true;
    }, 1000);
  
    var c = this.configs[val];
    $(".mycard").removeClass("current");
    if (!topView && !bottomView)
      $(".side" + (val + 1)).addClass("current");
  
    $(".mycard").removeClass("isBack");
    if (!topView && !bottomView)
      $(".side" + (((val + 2) % 4) + 1)).addClass("isBack");
  
    $(".mycard").addClass("transit");
    let rotZ = 0;
    if (topView) {
      this.wasTopView = true
      rotZ = -90;
      $(".bottom").removeClass("invisible");
    } else {
      $(".bottom").addClass("invisible");
    }
  
    if (bottomView) {
      this.wasBottomView = true;
      rotZ = 90;
      $(".top").removeClass("invisible");
    } else {
      $(".top").addClass("invisible");
    }
  
    $(".bottom").css("transform", "rotateX(" + (90 + rotZ) + "deg) rotateZ(0deg) translateZ(-600px)");
    $(".top").css("transform", "rotateX(" + (90 - rotZ) + "deg) rotateZ(0deg) translateZ(-600px)");
  
    $(".side" + c[0]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(${rotZ}deg) rotateZ(0deg) rotateY(0deg) translateZ(${this.zOrigin}px) `);
    $(".side" + c[1]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(${rotZ}deg) rotateZ(0deg) rotateY(-90deg) translateZ(${this.zOrigin}px)`  );
    if (dir === -1) {
      $(".side" + c[2]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(${rotZ}deg) rotateZ(0deg) rotateY(-180deg) translateZ(${this.zOrigin}px)` );
    } else {
      $(".side" + c[2]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(${rotZ}deg) rotateZ(0deg) rotateY(180deg) translateZ(${this.zOrigin}px)` );
    }
    $(".side" + c[3]).css("transform", `translateZ(-${this.zOrigin}px) rotateX(${rotZ}deg) rotateZ(0deg) rotateY(90deg) translateZ(${this.zOrigin}px)` );
  
  }
}
