import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sia',
  templateUrl: './sia.component.html',
  styleUrls: ['./sia.component.css']
})
export class SiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
