<cube-side>
    <div class="container" style="min-height: calc(100vh - 50px); padding-top: 70px; padding-bottom: 50px;">
        <span style="position: absolute; top: 50%;transform: translateX(-50%) translateY(-50%); line-height: 150%; font-size: 25px;">
            E-Mail: <br style="line-height: 70px;">
            <ul>
                <li style="font-size: 25px;"><a class="link" href="mailto:jakob@neinkob.de">jakob@neinkob.de</a>
                </li>
            </ul>
            <br>
            More Links:<br style="line-height: 70px;">
            <ul>
                <li style="font-size: 25px;"><i class="fas fa-angle-double-right"></i>&nbsp;<a class="link" href="https://neinkob.de">neinkob.de</a></li>
                <li style="font-size: 25px;"><i class="fab fa-github"></i>&nbsp;<a class="link" href="https://github.com/neinkob15/">github.com</a></li>
                <li style="font-size: 25px;"><i class="fab fa-codepen"></i>&nbsp;<a class="link" href="https://codepen.io/j4k0bkg">codepen.io</a></li>
            </ul>
        </span>
    </div>
</cube-side>