<static-page title="Die CVJM-Webseite" subtitle="seit 2016" backUrl="/portfolio">
    <div style="margin-top: 20px;">
        Seit dem Jahr 2016 bin ich ehrenamtlich für die Betreuung der Webseite des CVJMs in meinem Heimatdorf Diedelsheim zuständig.
        Ich programmierte ein neues, responsives Webseiten-Theme für das Framework Wordpress, sodass die Webseite einen neuen Look erhielt.<br>
        Wer möchte kann sie sich gerne anschauen unter <a target="_blank" class="link" href="http://cvjm-diedelsheim.de">cvjm&#8209;diedelsheim.de</a>.
        <br><br><br>
        Screenshots:<br>
        <a href="assets/img/portfolio/media/cvjm/websiteFull.png" class="glightbox" data-gallery="gallery1" data-glightbox="title: Die Webseite in der Desktop-Ansicht; descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
            <!--<a href="#" onclick="lightbox.open()" class="link">Zur Gallerie</a>-->
            <img src="assets/img/portfolio/media/cvjm/websiteFull.png" style="padding-top: 10px; height: 200px; vertical-align: top; margin-right: 20px;"/>
        </a>
        <a href="assets/img/portfolio/media/cvjm/websiteSmall.png" class="glightbox" data-gallery="gallery1" data-glightbox="title: ... und in der mobilen Ansicht; descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
            <img src="assets/img/portfolio/media/cvjm/websiteSmall.png" style="padding-top: 10px; height: 200px;"/>
        </a>
    </div>
</static-page>
