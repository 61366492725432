<static-page title="Privacy statement" menuUrl="/">
    <p style="margin-top: 20px;">
        <i>General information:</i>
        <p>
            There is no storage of any data. If you have any questions, please contact the website owner (see Impressum).
    <h1>&gt;&gt; Privacy statement for the app LightUp</h1>
    <p style="margin-top: 20px;">
        <i>Privacy Policy</i> 
        <p>
            Jakob Gietl built the LightUp app as
            a Free app. This SERVICE is provided by
            Jakob Gietl at no cost and is intended for use as
            is.
        <p>
            This page is used to inform visitors regarding my
            policies with the collection, use, and disclosure of Personal
            Information if anyone decided to use my Service.
        <p>
            If you choose to use my Service, then you agree to
            the collection and use of information in relation to this
            policy. The Personal Information that I collect is
            used for providing and improving the Service. I will not use or share your information with
            anyone except as described in this Privacy Policy.
        <p>
            The terms used in this Privacy Policy have the same meanings
            as in our Terms and Conditions, which is accessible at
            LightUp unless otherwise defined in this Privacy Policy.
    <p style="margin-top: 20px;"><i>Information Collection and Use</i>
    <p>
        For a better experience, while using our Service, I
        may require you to provide us with certain personally
        identifiable information. The information that
        I request will be retained on your device and is not collected by me in any way.
    <p>
        The app does use third party services that may collect
        information used to identify you.
    <p>
        Link to privacy policy of third party service providers used
        by the app
    <ul>
        <li><a class="link" style="color: inherit; font-size: 20px;" href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li>
    </ul>
    <p style="margin-top: 20px;"><i>Log Data</i>
    <p>
        I want to inform you that whenever you
        use my Service, in a case of an error in the app
        I collect data and information (through third party
        products) on your phone called Log Data. This Log Data may
        include information such as your device Internet Protocol
        (“IP”) address, device name, operating system version, the
        configuration of the app when utilizing my Service,
        the time and date of your use of the Service, and other
        statistics.
    <p style="margin-top: 20px;"><i>Cookies</i> 
    <p>
        Cookies are files with a small amount of data that are
        commonly used as anonymous unique identifiers. These are sent
        to your browser from the websites that you visit and are
        stored on your device's internal memory.
    <p>
        This Service does not use these “cookies” explicitly. However,
        the app may use third party code and libraries that use
        “cookies” to collect information and improve their services.
        You have the option to either accept or refuse these cookies
        and know when a cookie is being sent to your device. If you
        choose to refuse our cookies, you may not be able to use some
        portions of this Service.
    <p style="margin-top: 20px;"><i>Service Providers</i>
    <p>
        I may employ third-party companies and
        individuals due to the following reasons:
    <ul style="color: inherit; font-size: 20px;">
        <li>To facilitate our Service;</li> 
        <li>To provide the Service on our behalf;</li> 
        <li>To perform Service-related services; or</li> 
        <li>To assist us in analyzing how our Service is used.</li>
    </ul> 
    <p>
        I want to inform users of this Service
        that these third parties have access to your Personal
        Information. The reason is to perform the tasks assigned to
        them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
    <p style="margin-top: 20px;"><i>Security</i>
    <p>
        I value your trust in providing us your
        Personal Information, thus we are striving to use commercially
        acceptable means of protecting it. But remember that no method
        of transmission over the internet, or method of electronic
        storage is 100% secure and reliable, and I cannot
        guarantee its absolute security.
    <p style="margin-top: 20px;"><i>Links to Other Sites</i>
    <p>
        This Service may contain links to other sites. If you click on
        a third-party link, you will be directed to that site. Note
        that these external sites are not operated by me.
        Therefore, I strongly advise you to review the
        Privacy Policy of these websites. I have
        no control over and assume no responsibility for the content,
        privacy policies, or practices of any third-party sites or
        services.
    <p style="margin-top: 20px;"><i>Changes to This Privacy Policy</i>
    <p>
        I may update our Privacy Policy from
        time to time. Thus, you are advised to review this page
        periodically for any changes. I will
        notify you of any changes by posting the new Privacy Policy on
        this page.
    <p>This policy is effective as of 2020-05-27
    <p style="margin-top: 20px;"><i>Contact Us</i>
    <p>
        If you have any questions or suggestions about my
        Privacy Policy, do not hesitate to contact me at Jakob Gietl, mail@jakobgietl.de.
    <p>
        This privacy policy page was created at
        <a class="link" href="https://privacypolicytemplate.net" target="_blank">privacypolicytemplate.net</a>
        and modified/generated by
        <a class="link" href="https://app-privacy-policy-generator.firebaseapp.com/" target="_blank">App Privacy Policy Generator</a>
</static-page>