import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import GLightbox from '../../assets/js/glightbox.min.js'

@Component({
  selector: 'static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.css']
})
export class StaticPageComponent implements OnInit {

  @Input('title') title: string
  @Input('subtitle') subtitle: string
  @Input('backUrl') backUrl: string
  @Input('menuUrl') menuUrl: string

  constructor(private location: Location) { }

  ngOnInit(): void {
    GLightbox()
  }

  back() {
    this.location.back()
  }
}
