<static-page title="Javascript Web-Apps" subtitle="seit 2014" backUrl="/portfolio">
    <div style="margin-top: 20px;">
        Zu meinen Hobbies gehört auch die Programmierung von kleinen Web-Apps und Spielen. Diese sind in der Sprache Javascript implementiert und lassen sich somit in (fast) jedem Browser starten.
        Schau auch auf meiner Spieleseite <a class="link" target="_blank" href="https://neinkob.de">neinkob.de</a>: Diese Seite ist speziell für meine Web-Spiele gedacht.<br><br>
        Eine Auswahl von meinen Apps ist hier dargestellt. Wer Erweiterungsmöglichkeiten oder neue Spielideen hat, kann gerne auf mich zukommen.<br><br>
        <div class="grid">
          <a target="_blank" href="https://neinkob.de/games/tic/index.html"><div style="background-image: url('assets/img/portfolio/media/webapps/tictactoe.png');"></div></a>
          <a target="_blank" href="https://neinkob.de/games/tetris/index.html"><div style="background-image: url('assets/img/portfolio/media/webapps/tetris.png');"></div></a>
          <a target="_blank" href="https://neinkob.de/games/2048/index.html"><div style="background-image: url('assets/img/portfolio/media/webapps/2084.png');"></div></a>
          <a target="_blank" href="https://neinkob.de/games/mines/index.html"><div style="background-image: url('assets/img/portfolio/media/webapps/minesweeper.png');"></div></a>
          <a target="_blank" href="https://neinkob.de/games/tothemax/index.html"><div style="background-image: url('assets/img/portfolio/media/webapps/tothemax.png');"></div></a>
          <a target="_blank" href="https://neinkob.de/games/snake/snake.html"><div style="background-image: url('assets/img/portfolio/media/webapps/snake.png');"></div></a>
          <a target="_blank" href="https://neinkob.de/games/draw/draw.html"><div style="background-image: url('assets/img/portfolio/media/webapps/draw.png');"></div></a>
          <a target="_blank" href="https://codepen.io/j4k0bkg/full/JeLGOR"><div style="background-image: url('assets/img/portfolio/media/webapps/tesseract.png');"></div></a>
        </div>
    </div>
</static-page>