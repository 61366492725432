<static-page title="LightUp" subtitle="Februar 2016" backUrl="/portfolio">
    <div style="margin-top: 20px;">
        In meiner Freizeit habe ich angefangen, mich mit der Spiele-Entwicklung unter Android auseinanderzusetzen.
        <br><br>
        Herausgekommen ist das Spiel LightUp, ein kleines Puzzle-Strategie Spiel. Es geht darum, die Flächen entsprechend der Rahmenfarbe mit Hilfe von abstrakten Leuchttürmen auszufüllen.
        Probier es gerne selbst aus! &rarr; <a class="link" target="_blank" href="https://play.google.com/store/apps/details?id=de.neinkob.lightup">Playstore</a>
        <br><br>
        <a href="assets/img/portfolio/media/lightup/screenshot1.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <img src="assets/img/portfolio/media/lightup/screenshot1.jpg" style="padding-top: 10px; height: 200px; vertical-align: top; margin-right: 20px;"/>
        </a>
        <a href="assets/img/portfolio/media/lightup/screenshot2.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <img src="assets/img/portfolio/media/lightup/screenshot2.jpg" style="padding-top: 10px; height: 200px; vertical-align: top; margin-right: 20px;"/>
        </a>
    </div>
</static-page>