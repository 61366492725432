<main id="main" style="height: calc(var(--vh, 1vh) * 100)">
      <!--<img class="svg" src="assets/img/panda.svg" />-->
      <!--<h3 class="mymenu">MENU</h3>-->
      <i class="menuclose fas fa-times" (touchstart)="crossClick($event)" (click)="crossClick($event)"></i>

      <!-- the *magic* cube -->
      <div class="cube">

        <!-- front side -->
        <div class="side1 mycard current">
    <div class="mycontent">
        <div class="mycontainer">
            <span class="left"><i class="fas fa-arrow-left" (click)="turnLeftClick($event)"></i></span>
            <span class="right"><i class="fas fa-arrow-right" (click)="turnRightClick($event)"></i></span>
            <span class="myselect"><i class="fas fa-expand-arrows-alt"></i></span>
          <div class="mytitle">
            <span>Jakob&nbsp;Gietl</span><br>
            <span class="line"></span>
            <div class="submytitle">
              <ul>
                <li>&gt;&nbsp;Web&#8209;Designer&nbsp;&lt;</li>
                <li>&gt;&nbsp;IT&#8209;Administrator&nbsp;&lt;</li>
                <li>&gt;&nbsp;Software&#8209;Developer&nbsp;&lt;</li>
              </ul>
            </div>
          </div>
          <span class="more" (click)="scrollDown()">
    <i class="fas fa-angle-double-down"></i>
</span>
<front-side></front-side>
         </div>
    </div>
</div>

        <!-- left side -->
        <div class="side2 mycard">
    <div class="mycontent">
        <div class="mycontainer">
            <span class="left"><i class="fas fa-arrow-left" (click)="turnLeftClick($event)"></i></span>
            <span class="right"><i class="fas fa-arrow-right" (click)="turnRightClick($event)"></i></span>
            <span class="myselect"><i class="fas fa-expand-arrows-alt"></i></span>
          <div class="mytitle">
                <span>Contact</span><br>
                <span class="line"></span>
                <div class="submytitle">&gt;&nbsp;jakob@neinkob.de&nbsp;&lt;</div>
              </div>
              <span class="more" (click)="scrollDown()">
    <i class="fas fa-angle-double-down"></i>
</span>
            <left-side></left-side> 
         </div>
    </div>
</div>

        <!-- back side -->
        <div class="side3 mycard">
    <div class="mycontent">
        <div class="mycontainer">
            <span class="left"><i class="fas fa-arrow-left" (click)="turnLeftClick($event)"></i></span>
            <span class="right"><i class="fas fa-arrow-right" (click)="turnRightClick($event)"></i></span>
            <span class="myselect"><i class="fas fa-expand-arrows-alt"></i></span>
          <div class="mytitle">
                <span>Portfolio</span><br>
                <span class="line"></span>
                <div class="submytitle"><ul>
                  <li>&gt;&nbsp;Websites&nbsp;&lt;</li>
                  <li>&gt;&nbsp;Home&#8209;Automation&nbsp;&lt;</li>
                  <li>&gt;&nbsp;Web&#8209;Games&nbsp;&lt;</li>
                  <li>&gt;&nbsp;Apps&nbsp;&lt;</li>
                </ul></div>
              </div>
              <span class="more" (click)="scrollDown()">
    <i class="fas fa-angle-double-down"></i>
</span>
                <back-side></back-side>
         </div>
    </div>
</div>

        <!-- right side -->
        <div class="side4 mycard">
    <div class="mycontent">
        <div class="mycontainer">
            <span class="left"><i class="fas fa-arrow-left" (click)="turnLeftClick($event)"></i></span>
            <span class="right"><i class="fas fa-arrow-right" (click)="turnRightClick($event)"></i></span>
            <span class="myselect"><i class="fas fa-expand-arrows-alt"></i></span>
          <div class="mytitle">
                <span>About&nbsp;Me</span><br>
                <span class="line"></span>
                <div class="submytitle">&gt;&nbsp;Who&nbsp;am&nbsp;I?&nbsp;&lt;</div>
              </div>
              <span class="more" (click)="scrollDown()">
    <i class="fas fa-angle-double-down"></i>
</span>
<right-side></right-side>              
        </div>
    </div>
</div>
        <!-- bottom side -->
        <div class="bottom mycard invisible">
          <div style="border: 0px solid gray; padding: 20px; border-radius: 7px; margin: 0 auto;">
            Made with <span style="color: red;">❤</span><br>
            by Jakob Gietl
          </div>
        </div>
        <div class="top mycard invisible">
          <div style="border: 0px solid gray; padding: 20px; border-radius: 7px; margin: 0 auto;">
            What did you expect to see down here?!?
          </div>
        </div>
      </div>
    </main>