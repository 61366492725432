<static-page title="Waterdata" subtitle="August 2018" backUrl="/portfolio">
    <div style="margin-top: 20px;">
        Im August 2018 erhielt ich das Angebot zu einer "Applied Summer School" in Italien, genauer in der Markenregion. Zusammen mit einer Kommilitonin und einigen Studenten aus anderen Dualen
        Hochschulen fuhren wir dann Anfang August mit Mietwagen nach Italien. Dort wohnten wir die zehn Tage lang in einem entlegenen Hotel und fuhren fast jeden Tag zur nahegelegenen Firma "Loccioni",
        die Kontroll- und Messsysteme entwickelt. Neben interessanten Vorträgen und Informationsveranstaltungen durften wir am Ende bei einem internen Hackathon auch selbst ein Projekt angehen.
        <br><br>Die Aufgabe unseres Teams war die Entwicklung eines kleinen, ferngesteuerten Bootes, welches mit verschiedenen Sensoren ausgestattet war, und die Daten direkt auf einer Webseite darstellen sollte.
        Ich war dabei zuständig für die Erstellung der Webseite und die Visualisierung der Daten.
        Die Ergebnisse unseres Projekts sind unten in der kleinen Bildergalerie dargestellt. Unser Team erreichte damit den zweiten Platz (von&nbsp;6) und jeder bekam dafür einen Raspberry Pi!
        <br><br>

        <a href="assets/img/portfolio/media/waterdata/boat.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="title: Das fernsteuerbare Schiff mit den Sensoren; descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <img src="assets/img/portfolio/media/waterdata/boat.jpg" style="padding-top: 10px; height: 200px; vertical-align: top; margin-right: 20px;"/>
        </a>
        <a href="assets/img/portfolio/media/waterdata/waterdata-title.png" class="glightbox" data-gallery="gallery1" data-glightbox="title: Die Startfolie der Endpräsentation mit eigenem Logo; descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <!--<a href="#" onclick="lightbox.open()" class="link">Zur Gallerie</a>-->
          <img src="assets/img/portfolio/media/waterdata/waterdata-title.png" style="padding-top: 10px; height: 200px; vertical-align: top; margin-right: 20px;"/>
        </a>
        <a href="assets/img/portfolio/media/waterdata/dataflow.png" class="glightbox" data-gallery="gallery1" data-glightbox="title: Eine Visualisierung des gesamten Datenflusses, vom Boot bis zum Webserver; descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <!--<a href="#" onclick="lightbox.open()" class="link">Zur Gallerie</a>-->
          <img src="assets/img/portfolio/media/waterdata/dataflow.png" style="padding-top: 10px; height: 200px; vertical-align: top; margin-right: 20px; margin-top: 10px;"/>
        </a>
        <a href="assets/img/portfolio/media/waterdata/screenshot1.png" class="glightbox" data-gallery="gallery1" data-glightbox="title: Die mobile Ansicht der responsiven Webseite, vom Boot bis zum Webserver; descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <!--<a href="#" onclick="lightbox.open()" class="link">Zur Gallerie</a>-->
          <img src="assets/img/portfolio/media/waterdata/screenshot1.png" style="padding-top: 10px; height: 200px; vertical-align: top; margin-right: 20px; margin-top: 10px;"/>
        </a>
        <a href="assets/img/portfolio/media/waterdata/screenshot2.png" class="glightbox" data-gallery="gallery1" data-glightbox="title: Die Statistik-Seite, leider aus Zeitgründen nur mit Zufallswerten; descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <!--<a href="#" onclick="lightbox.open()" class="link">Zur Gallerie</a>-->
          <img src="assets/img/portfolio/media/waterdata/screenshot2.png" style="padding-top: 10px; height: 200px; vertical-align: top; margin-right: 20px; margin-top: 10px;"/>
        </a>
        <a href="assets/img/portfolio/media/waterdata/test.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="title: Ein erster Test im Freien; descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <!--<a href="#" onclick="lightbox.open()" class="link">Zur Gallerie</a>-->
          <img src="assets/img/portfolio/media/waterdata/test.jpg" style="padding-top: 10px; height: 200px; vertical-align: top; margin-right: 20px; margin-top: 10px;"/>
        </a>
    </div>
</static-page>