<cube-side>
    <div class="container">
        <ul style="margin-bottom: 50px;">
            <!-- do not delete! It might be important when applying for a new job some time :) -->
            <!-- <li style="padding-top: 100px;"><h2><a href="lifeMap.html" class="link lifeStoryMap">&gt;&gt;&nbsp;Life&nbsp;Story&nbsp;Map™</a> </h2><span style="font-size: 18px; line-height: 50px;">©  2019, Jakob Gietl</span></li>-->
            <li style="padding-top: 100px;"><h2>Education</h2></li>
            <div class="card" style="margin-top: 30px; box-shadow: 1px 1px 5px #555;">
                <div class="card-image">
                    <figure class="image is-3by1">
                        <img src="assets/img/hka.jpg" alt="Placeholder image" />
                    </figure>
                </div>
                <div class="card-content">
                    <div class="content" style="font-size: 25px; line-height: 1.5;">
                        <span style="font-weight: bold; font-size: 35px;">M. Sc. Information Technology</span><br>
                        <span style="font-size: 15px; margin-bottom: 15px;">University of Applied Sciences Karlsruhe, 2021 - 2022</span><br>
                            &lt;&gt;<br>
                        <span style="margin-top: 15px;">Main Courses</span><br>
                        <span style="font-size: 15px; margin-bottom: 15px;">Media Design, Software Architecture</span><br>
                            &lt;&gt;<br>
                        <span style="margin-top: 15px;">Elective Courses</span><br>
                        <span style="font-size: 15px; margin-bottom: 15px;">Game Development, Cryptography, Distributed Systems</span><br>
                            &lt;&gt;<br>
                        <span style="margin-top: 15px;">Master Thesis</span><br>
                        <span style="font-size: 15px; margin-left: 20px; margin-right: 20px; margin-top: 13px; display: inline-block; line-height: 1.3;">Konzeption eines innovativen Ansatzes zur automatisierten Bereitstellung von Netzinfrastruktur</span><br>
                            &lt;&gt;
                        <figure class="image" style="margin-top: 15px; margin-bottom: 20px;">
                            <img src="assets/img/hka-logo.png" alt="Placeholder image" style="width: 300px;" />
                        </figure>
                    </div>
                </div>
            </div>
            <div class="card" style="margin-top: 30px; box-shadow: 1px 1px 5px #555;">
                <div class="card-image">
                    <figure class="image is-3by1">
                        <img src="assets/img/dhbw.jpg" alt="Placeholder image" />
                    </figure>
                </div>
                <div class="card-content">
                    <div class="content" style="font-size: 25px; line-height: 1.5;">
                        <span style="font-weight: bold; font-size: 35px;">B. Sc. Information Technology</span><br>
                        <span style="font-size: 15px; margin-bottom: 15px;">Cooperative State University Karlsruhe, 2017 - 2020</span><br>
                            &lt;&gt;<br>
                        <span style="margin-top: 15px;">Main Courses</span><br>
                        <span style="font-size: 15px; margin-bottom: 15px;">Network technology, Information systems</span><br>
                            &lt;&gt;<br>
                        <span style="margin-top: 15px;">Elective Courses</span><br>
                        <span style="font-size: 15px; margin-bottom: 15px;">Gamification, Speech and knowledge processing, IT-Forensics</span><br>
                            &lt;&gt;<br>
                        <span style="margin-top: 15px;">Bachelor Thesis</span><br>
                        <span style="font-size: 15px; margin-left: 20px; margin-right: 20px; margin-top: 13px; display: inline-block; line-height: 1.3;">Entwicklung einer Software zur automatischen Auswertung von Konfigurationen von Netzkomponenten</span><br>
                            &lt;&gt;
                        <figure class="image" style="margin-top: 15px; margin-bottom: 20px;">
                            <img src="assets/img/dhbw-logo.jpg" alt="Placeholder image" style="width: 160px;" />
                        </figure>
                    </div>
                </div>
            </div>
                    <li style="padding-top: 80px; margin-bottom: 30px;"><h2>Achievements</h2></li>
                    <div class="columns">
                      <div class="column">
                        <i class="fas fa-medal"></i>
                        <br>
                        <p style="font-size: 20px;">
                          Students Award<br><span title="Melanchthon-Gymnasium Bretten" style="width: 90%; font-size: 15px; margin-top: 5px;">DHBW</span>
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px;">
                          Best final grade in the information technology course
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px; margin-bottom: 15px;">
                          October 2020
                        </p>
                      </div>
                      <div class="column">
                        <i class="fas fa-medal"></i>
                        <br>
                        <p style="font-size: 20px;">
                          CCNA Certificate<br><span title="Melanchthon-Gymnasium Bretten" style="width: 90%; font-size: 15px; margin-top: 5px;">Cisco</span>
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px;">
                          Cisco Certified Network Associate - Routing and Switching
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px; margin-bottom: 15px;">
                          November 2019
                        </p>
                      </div>
                      <div class="column">
                        <i class="fas fa-medal"></i>
                        <br>
                        <p style="font-size: 20px;">
                          &ldquo;ADA-Schein&rdquo;<br>
                          <span title="Karlsruher Institut für Technologie" style="font-size: 15px; width: 90%; margin-top: 5px;">DHBW</span>
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px;">
                          Certificate in Teaching and Training
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px; margin-bottom: 15px;">
                          November 2019
                        </p>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <i class="fas fa-medal"></i>
                        <br>
                        <p style="font-size: 20px;">
                          Students Award<br><span title="Melanchthon-Gymnasium Bretten" style="width: 90%; font-size: 15px; margin-top: 5px;">Melanchthon-Gymnasium</span>
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px;">
                          Award for best achievements in computer science
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px; margin-bottom: 15px;">
                          July 2017
                        </p>
                      </div>
                      <!--<div class="column">
                        <i class="fas fa-medal"></i>
                        <br>
                        <p style="font-size: 20px;">
                          Students Award<br><span title="Melanchthon-Gymnasium Bretten" style="width: 90%; font-size: 15px; margin-top: 5px;">Melanchthon-Gymnasium</span>
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px;">
                          Prize for excellent achievements
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px; margin-bottom: 15px;">
                          July 2017
                        </p>
                      </div>-->
                      <div class="column">
                        <i class="fas fa-medal"></i>
                        <br>
                        <p style="font-size: 20px;">
                          Math Certificate<br><span title="Karlsruher Institut für Technologie" style="font-size: 15px; width: 90%; margin-top: 5px;">KIT</span>
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px;">
                          Central exam in adv. Mathematics, top 1.2% in Baden-Wuerttemberg
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px; margin-bottom: 15px;">
                          December 2016
                        </p>
                      </div>
                      <div class="column">
                        <i class="fas fa-medal"></i>
                        <br>
                        <p style="font-size: 20px;">
                          English Certificate<br><span title="Karlsruher Institut für Technologie" style="font-size: 15px; width: 90%; margin-top: 5px;">Cavendish School Bournemouth</span>
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px;">
                          Successfully completed an English Language Course
                        </p>
                        <p style="font-size: 15px; line-height: 50px;">&lt;&gt;</p>
                        <p style="font-size: 15px; margin-bottom: 15px;">
                          September 2015
                        </p>
                      </div>
                    </div>
                    <li style="padding-top: 80px; margin-bottom: 30px;"><h2>Hobbies</h2></li>
                    <div class="columns" style="font-family: Roboto;">
                      <div class="column has-text-centered">
                        <img src="assets/img/juggling2.png" style="height: 40px;" />
                        <p style="font-size: 20px; margin-bottom: 10px;">Juggling</p>
                      </div>
                      <div class="column has-text-centered">
                        <img src="assets/img/piano.svg" style="height: 40px;" />
                        <p style="font-size: 20px; margin-bottom: 10px;">Piano</p>
                      </div>
                      <div class="column has-text-centered">
                        <i class="fas fa-bicycle" style="font-size: 40px;"></i>
                        <p style="font-size: 20px; margin-top: 5px; margin-bottom: 4px;">Bicycle tours</p>
                      </div>
                      <div class="column has-text-centered">
                        <i class="fas fa-microchip" style="font-size: 40px;"></i>
                        <p style="font-size: 20px; margin-top: 5px; margin-bottom: 10px;">DIY</p>
                      </div>
                    </div>
                  </ul>
                </div>
</cube-side>
