<div class="aftercontentbackground"></div>
<div class="afterContent" style="height: auto;">
            <ng-content></ng-content>
          <div class="myfooter" style="position: relative; height: 50px; bottom: 0;">
        <div class="leftFooter" style="position: relative; margin-top: 20px; margin-left: 30px; float: left;width: calc(50%-30px); left: 0;">
            <a class="link" routerLink="/impressum">Impressum</a>
        </div>
        <div class="rightFooter" style="position: relative; margin-top: 20px; margin-right: 30px; float: right; width: calc(50%-30px); right: 0;">
            <a class="link" routerLink="/privacy">Privacy</a>
        </div>
    </div>
</div>