<main id="main" class="impressum" style="visibility: visible; height: 100vh; min-height: 100vh; overflow-y: auto; background: radial-gradient(var(--darkcolor), #000);">
    <i *ngIf="backUrl" [routerLink]="backUrl" class="far fa-arrow-alt-circle-left" style="color: var(--lightcolor2); font-size: 50px; margin: 15px; position: fixed; z-index: 20;"></i>  
    <a class="link" *ngIf="menuUrl" [routerLink]="menuUrl" style="position: fixed; font-size: 22px; font-style: normal; right: 50px; top: 20px; z-index: 20;"><!--<span style="position: fixed; font-size: 40px; right: 30px; top: 10px; line-height: 1.5; text-align: center; letter-spacing: 5px;"><i class="fas fa-bars"></i></span>-->
        Home
    </a>
    <div class="container" style="padding-bottom: 100px; line-height: 1.5; font-size: 20px;">
        <h1>&gt;&gt; {{title}}</h1>
        <span style="margin-bottom: 0px; margin-top: 5px; display: block; font-size: 18px;">{{subtitle}}</span>
        <ng-content></ng-content>
    </div>
</main>
    