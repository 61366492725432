import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { AppRoutingModule } from './app-routing.module';
import { CubeComponent } from './cube/cube.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CubeSideComponent } from './cube/cube-side/cube-side.component';
import { FrontSideComponent } from './cube/front-side/front-side.component';
import { RightSideComponent } from './cube/right-side/right-side.component';
import { BackSideComponent } from './cube/back-side/back-side.component';
import { LeftSideComponent } from './cube/left-side/left-side.component';
import { WaterdataComponent } from './portfolio/waterdata/waterdata.component';
import { CvjmComponent } from './portfolio/cvjm/cvjm.component';
import { SiaComponent } from './portfolio/sia/sia.component';
import { WebappsComponent } from './portfolio/webapps/webapps.component';
import { LightupComponent } from './portfolio/lightup/lightup.component';
import { SmartjuggleComponent } from './portfolio/smartjuggle/smartjuggle.component';

@NgModule({
  declarations: [
    AppComponent,
    ImpressumComponent,
    CubeComponent,
    StaticPageComponent,
    PrivacyComponent,
    CubeSideComponent,
    FrontSideComponent,
    RightSideComponent,
    BackSideComponent,
    LeftSideComponent,
    WaterdataComponent,
    CvjmComponent,
    SiaComponent,
    WebappsComponent,
    LightupComponent,
    SmartjuggleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
