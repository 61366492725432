import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'cube-side',
  templateUrl: './cube-side.component.html',
  styleUrls: ['./cube-side.component.css']
})
export class CubeSideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
