<cube-side>
    <div class="container" style="min-height: calc(100vh - 50px); padding-top: 70px; padding-bottom: 50px;">
        <div class="mygrid">
            <a class="item" routerLink="/portfolio/waterdata"><div style="background-image: url('assets/img/portfolio/summerschool.png');"></div></a>
            <a class="item" routerLink="/portfolio/cvjm"><div style="background-image: url('assets/img/portfolio/cvjm.png');"></div></a>
            <a class="item" routerLink="/portfolio/sia"><div style="background-image: url('assets/img/portfolio/sia.jpg');"></div></a>
            <!--<a class="item" href="portfolio/camsparent"><div style="background-image: url('assets/img/portfolio/camsparent.png');"></div>-->
            <!--<a class="item" href="portfolio/fischertechnik"><div style="background-image: url('assets/img/portfolio/taktstrasse.jpg');"></div></a>-->
            <a class="item" routerLink="/portfolio/webapps"><div style="background-image: url('assets/img/portfolio/webapps.png');"></div></a>
            <a class="item" routerLink="/portfolio/lightup"><div style="background-image: url('assets/img/portfolio/lightup.png');"></div></a>
            <a class="item" routerLink="/portfolio/smartjuggle"><div style="background-image: url('assets/img/portfolio/smartjuggle.jpg');"></div></a>
        </div>
    </div>
</cube-side>