<static-page title="Schüler-Ingenieur-Akademie" subtitle="2015-2016" backUrl="/portfolio">
    <div style="margin-top: 20px;">
        In der Oberstufe des Gymnasiums nahm ich an der sogenannten "Schüler-Ingenieur-Akademie" (kurz: SIA) teil. Dort besichtigten wir Firmen in der technischen Branche wie
        z.B. Daimler, SEW und Siemens.<br><br> Außerdem durften wir mit verschiedenen Robotern experimentieren. In den Bildern dargestellt sind der humanoide Roboter NAO, sowie
        ein selbstgebauter, balancierender LEGO-Mindstorms Roboter.<br><br>
        <a href="assets/img/portfolio/media/sia/nao.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <img src="assets/img/portfolio/media/sia/nao.jpg" style="padding-top: 10px; height: 250px; vertical-align: top; margin-right: 20px;"/>
        </a>
        <a href="assets/img/portfolio/media/sia/lego.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <!--<a href="#" onclick="lightbox.open()" class="link">Zur Gallerie</a>-->
          <img src="assets/img/portfolio/media/sia/lego.jpg" style="padding-top: 10px; height: 250px; vertical-align: top; margin-right: 20px;"/>
        </a>
    </div>
</static-page>