import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CubeComponent } from './cube/cube.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { CvjmComponent } from './portfolio/cvjm/cvjm.component';
import { LightupComponent } from './portfolio/lightup/lightup.component';
import { SiaComponent } from './portfolio/sia/sia.component';
import { SmartjuggleComponent } from './portfolio/smartjuggle/smartjuggle.component';
import { WaterdataComponent } from './portfolio/waterdata/waterdata.component';
import { WebappsComponent } from './portfolio/webapps/webapps.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: '', component: CubeComponent },
  { path: 'cube', component: CubeComponent },
  { path: 'aboutme', component: CubeComponent },
  { path: 'contact', component: CubeComponent },
  { path: 'portfolio', component: CubeComponent },
  { path: 'impressum', component: ImpressumComponent},
  { path: 'privacy', component: PrivacyComponent },
  { path: 'privacy.html', redirectTo: 'privacy', pathMatch: 'full' },
  { path: 'portfolio/waterdata', component: WaterdataComponent },
  { path: 'portfolio/cvjm', component: CvjmComponent },
  { path: 'portfolio/sia', component: SiaComponent },
  { path: 'portfolio/webapps', component: WebappsComponent },
  { path: 'portfolio/lightup', component: LightupComponent },
  { path: 'portfolio/smartjuggle', component: SmartjuggleComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }