import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smartjuggle',
  templateUrl: './smartjuggle.component.html',
  styleUrls: ['./smartjuggle.component.css']
})
export class SmartjuggleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
