<static-page title="Impressum" subtitle="Information according to § 5 TMG" menuUrl="/">
  <p style="margin-top: 60px;">
    Jakob Gietl <br>
    (Adress on request) <br>
  </p>
        
  <p style="margin-top: 20px;"> 
    <i>Represented by: </i><br>
    Jakob Gietl<br>
  </p>
  <p style="margin-top: 20px;">
    <i>Contact:</i> <br>
    Phone: (on request)<br>
    E-Mail: <a class="link" href='mailto:mail@jakobgietl.de'>mail@jakobgietl.de</a><br>
  </p>
  <p style="margin-top: 20px;">
    <i>Responsible for the content according to § 55 Abs. 2 RStV:</i><br>
    Jakob Gietl <br>
  </p><br>
  <p style="margin-top: 20px;">
    <i>Disclaimer: </i><i>Liability for contents</i><br><br>
    The contents of our pages were created with the greatest care. For the correctness, completeness and topicality of the contents we can take over however no guarantee. As a service provider we are responsible according to § 7 Abs.1 TMG for our own contents on these pages according to the general laws. According to §§ 8 to 10 TMG we are not obliged as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this respect is only possible from the time of knowledge of a concrete violation of the law. As soon as we become aware of such infringements, we will remove the content immediately.
    <br><br>
    <i>Liability for links</i><br><br>
    Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the contents of the linked pages. The linked pages were checked for possible legal infringements at the time of linking. Illegal contents were not recognisable at the time of linking. A permanent control of the contents of the linked pages is not reasonable without concrete evidence of an infringement. As soon as we become aware of any legal infringements, we will remove such links immediately.
    <br><br><i>Copyright</i><br><br>
    The contents and works on these pages created by the site operators are subject to German copyright law. Duplication, processing, distribution and any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Downloads and copies of these pages are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, contents of third parties are marked as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. As soon as we become aware of any infringements, we will remove such content immediately.<br>
  </p><br><br>
  Website Impressum created by <a class="link" href="https://www.impressum-generator.de">impressum-generator.de</a> from the <a class="link" href="https://www.kanzlei-hasselbach.de/">Kanzlei Hasselbach</a>
</static-page>