<static-page title="Smart Juggling Balls" subtitle="2020" backUrl="/portfolio">
    <div style="margin-top: 20px;">
        Im Rahmen unserer Studienarbeit entwickelten Fynn Arnold und ich smarte Jonglierbälle, die das Erlernen des Jonglierens vereinfachen sollten. 
        Mithilfe von Beschleunigungssensoren in den Bällen können die Anzahl der Würfe und die Wurfgenauigkeit gemessen werden.
        Über Bluetooth Low Energy werden die Bälle von einem Smartphone aus gesteuert und die entsprechenden Wurfdaten werden angezeigt. 
        <br><br>
        Die Bälle sind leider noch nicht verkaufsfähig. Es passieren noch zu viele Fehlauswertungen, da die Sensordaten zu ungenau sind. 
        Allerdings konnten wir mit diesem Prototyp bereits erste erfolgreiche Testversuche machen und zeigen, dass ein solches Projekt theoretisch machbar ist.
        <br>Und mit unserer sehr guten Endnote sind wir auf jeden Fall mehr als zufrieden!
        <br><br>
        <a href="assets/img/portfolio/media/smartjuggle/lightson.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <img src="assets/img/portfolio/media/smartjuggle/lightson.jpg" style="padding-top: 10px; height: 250px; vertical-align: top; margin-right: 20px;"/>
        </a>
        <a href="assets/img/portfolio/media/smartjuggle/screen-stats.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <img src="assets/img/portfolio/media/smartjuggle/screen-stats.jpg" style="padding-top: 10px; height: 250px; vertical-align: top; margin-right: 20px;"/>
        </a>
        <a href="assets/img/portfolio/media/smartjuggle/screen-training.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <img src="assets/img/portfolio/media/smartjuggle/screen-training.jpg" style="padding-top: 10px; height: 250px; vertical-align: top; margin-right: 20px;"/>
        </a>
        <a href="assets/img/portfolio/media/smartjuggle/hardware.jpg" class="glightbox" data-gallery="gallery1" data-glightbox="descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <img src="assets/img/portfolio/media/smartjuggle/hardware.jpg" style="padding-top: 10px; height: 250px; vertical-align: top; margin-right: 20px;"/>
        </a>
        <a href="assets/img/portfolio/media/smartjuggle/schaltplan.png" class="glightbox" data-gallery="gallery1" data-glightbox="descPosition: bottom; type: image; effect: fade; width: 900px; height: auto;">
          <img src="assets/img/portfolio/media/smartjuggle/schaltplan.png" style="padding-top: 10px; height: 250px; vertical-align: top; margin-right: 20px;"/>
        </a>
    </div>
</static-page>