import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'front-side',
  templateUrl: './front-side.component.html',
  styleUrls: ['./front-side.component.css']
})
export class FrontSideComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  imageClick(e: any) {
    alert("test")
    this.router.navigateByUrl("/assets/img/me.jpg")
    return false
  }
}
